<template>
  <div id="search">
    <SfBreadcrumbs class="breadcrumbs" :breadcrumbs="breadcrumbs" />
    <div v-if="searchStore.products.length || !initialFetchResult">
      <div class="search-info">
        <div class="heading">
          <SkeletonLoader
            v-if="!pageTitle"
            skeleton-class="heading-skeleton"
            width="300px"
            margin="0"
          />
          <VaimoHeading
            v-else
            :heading="pageTitle"
            :sub-heading="pageSubtitle"
            :animated-heading="false"
            heading-level="h1"
            sub-heading-level="h3"
          />
        </div>
      </div>

      <KlevuFiltersSidebar :manager="manager" />

      <div class="category-layout">
        <div class="main_filters">
          <div
            class="flex items-center self-start cursor-pointer"
            :aria-label="$t('Filters')"
            tabindex="0"
            @click="searchStore.toggleFilterSidebar()"
            @keypress.enter="searchStore.toggleFilterSidebar()"
          >
            <SvgImage icon="quick-filter" />
          </div>
          <div class="main_filters_inside">
            <a
              data-href="all"
              :aria-label="$i18n.t('View all')"
              tabindex="0"
              :class="typeOfView === 'all' ? 'active' : ''"
              @click="mainFiltersClick($event)"
              @keypress.enter="mainFiltersClick($event)"
            >
              {{ $i18n.t('View all') }}
              <span data-href="all">
                {{ searchStore.totalResultsFound }}
              </span>
            </a>
            <a
              data-href="products"
              :aria-label="$i18n.t('Products')"
              tabindex="0"
              :class="typeOfView === 'products' ? 'active' : ''"
              @click="mainFiltersClick($event)"
              @keypress.enter="mainFiltersClick($event)"
            >
              {{ $i18n.t('Products') }}
              <span data-href="products">{{
                searchStore.totalProductsFound
              }}</span>
            </a>
            <a
              data-href="pages"
              :class="typeOfView === 'pages' ? 'active' : ''"
              :aria-label="$i18n.t('Pages')"
              tabindex="0"
              @click="mainFiltersClick($event)"
              @keypress.enter="mainFiltersClick($event)"
            >
              {{ $i18n.t('Pages') }}
              <span data-href="pages">{{ validPages.length }}</span>
            </a>
          </div>
        </div>

        <div
          v-show="typeOfView === 'products' || typeOfView === 'all'"
          class="main section column"
        >
          <div id="products" class="products active">
            <CategoryProductGrid
              :products="products"
              :prices-loaded="true"
              :loading="productsLoading"
              :is-used-for-klevu-search-results="true"
              @click:add-to-cart="addItemToCart"
            />

            <div
              v-if="searchStore.products.length"
              class="products__display-opt load-more"
            >
              <div class="load-more__container">
                <div class="load-more__count">
                  {{
                    $t('You have seen {amount} of {total}', {
                      amount: searchStore.products.length,
                      total: searchStore.totalProductsFound
                    })
                  }}
                </div>
                <VaimoButton
                  v-show="
                    searchStore.products.length < searchStore.totalProductsFound
                  "
                  variant="default"
                  :aria-label="$t('Display more')"
                  tabindex="0"
                  :disabled="loading"
                  @click.native="appendProducts"
                  @keypress.native.enter="appendProducts"
                >
                  {{ $t('Display more') }}
                </VaimoButton>
              </div>
            </div>
          </div>
        </div>
      </div>
      <NostoSearch :query="searchStore.searchTerm" />
    </div>

    <div
      v-if="validPages.length"
      v-show="typeOfView === 'pages' || typeOfView === 'all'"
      id="pages"
      class="active"
    >
      <div class="container pleater">
        <div class="vaimo-information-pleats">
          <div v-for="page in validPages" :key="`cms-${page.name}`">
            <a
              class="vaimo-information-pleats__item"
              :aria-label="page.name"
              :href="localePath(getValidSlug(page.url))"
            >
              {{ page.name }}
            </a>
          </div>
        </div>
      </div>
    </div>

    <div
      v-if="!productsLoading && !searchStore.products.length && isFetchingEnded"
      key="no-results"
      class="no-results"
    >
      <KlevuSearchNoResults />
    </div>
  </div>
</template>

<script>
import {
  applyFilters,
  FilterManager,
  KlevuEvents,
  KlevuFetch,
  KlevuSearchSorting,
  KlevuTypeOfRecord,
  listFilters,
  search,
  sendSearchEvent,
  trendingProducts
} from '@klevu/core';
import {
  computed,
  onBeforeUnmount,
  onMounted,
  ref,
  useContext,
  useRoute,
  useRouter
} from '@nuxtjs/composition-api';
import { SfBreadcrumbs } from '@storefront-ui/vue';
import NostoSearch from 'atoms/NostoSearch.vue';

import SvgImage from '~/components/General/SvgImage.vue';
import SkeletonLoader from '~/components/SkeletonLoader/index.vue';
import { useProduct, useUiState } from '~/composables';
import { useGoogleTagManager, useLink } from '~/diptyqueTheme/composable';
import { useAddToCart } from '~/helpers/cart/addToCart';
import { Logger } from '~/helpers/logger';

import {
  searchStore as useSearch,
  useKlevuSearch,
  useQuickSearch,
  useUrlHelper
} from '../index';
import KlevuFiltersSidebar from './../components/KlevuFiltersSidebar.vue';

export default {
  name: 'KlevuSearchResults',
  components: {
    VaimoHeading: () =>
      import('~/diptyqueTheme/components/atoms/VaimoHeading.vue'),
    VaimoButton: () => import('atoms/VaimoButton.vue'),
    KlevuSearchNoResults: () => import('./KlevuSearchNoResults.vue'),
    CategoryProductGrid: () =>
      import(
        '~/modules/catalog/category/components/views/CategoryProductGrid.vue'
      ),
    SfBreadcrumbs,
    SkeletonLoader,
    KlevuFiltersSidebar,
    SvgImage,
    NostoSearch
  },
  setup(props, context) {
    useKlevuSearch().init();
    const { getProductList, loading: productsLoading } = useProduct();
    const products = ref([]);

    // Stores
    const searchStore = useSearch();
    searchStore.resetSearch();
    const quickSearchStore = useQuickSearch();
    const { addItemToCart } = useAddToCart();

    // Category related vars
    const { $i18n } = context.root;
    const { app } = useContext();
    const route = useRoute();
    const router = useRouter();
    const { toggleFilterSidebar } = useUiState();
    const { getFitersFromUrl } = useUrlHelper();
    const { normalizeLink } = useLink();
    const { getResultSearchDetails, getViewItemListDetails } =
      useGoogleTagManager();
    const routeQuery = route.value?.query?.q || '';
    const isFetchingEnded = ref(false);
    const locale = app.i18n.localeProperties?.code;

    const pageTitle = computed(() => {
      if (!searchStore.totalResultsFound) return '';
      return `${searchStore.totalResultsFound} ${$i18n.t(
        'results of the search'
      )}`;
    });

    const pageSubtitle = computed(
      () => `${$i18n.t('for')} ‘${searchStore.searchTerm}’`
    );

    const pageTitleNoResults = computed(
      () => `${$i18n.t('We are sorry. We did not find any matches')}`
    );

    const breadcrumbs = computed(() => {
      const textSearchResults = `${$i18n.t('Search results for')}`;
      return [
        {
          link: normalizeLink('/'),
          text: `${$i18n.t('Home')}`
        },
        {
          link: route.value.fullPath,
          text:
            $i18n.localeProperties?.code === 'ja_jp'
              ? `'${searchStore.searchTerm}' ${textSearchResults}`
              : `${textSearchResults} '${searchStore.searchTerm}'`
        }
      ];
    });

    const getTypeOfView = () => {
      let result = 'all';
      if (
        Object.prototype.hasOwnProperty.call(route.value?.query, 'products')
      ) {
        result = 'products';
      } else if (
        Object.prototype.hasOwnProperty.call(route.value?.query, 'pages')
      ) {
        result = 'pages';
      }
      return result;
    };

    const typeOfView = ref(getTypeOfView());

    const mainFiltersClick = ($event) => {
      if ($event) {
        $event.preventDefault();
        typeOfView.value = $event.target.getAttribute('data-href');
      }
    };

    const loading = ref(false);
    const {
      $klevuSearch: { searchLimit, typeOfRecords, filterOptionsLimit }
    } = useContext();

    // Search related code
    const manager = new FilterManager();
    let prevRes;
    let vw = ref(0);

    const setParamToSearchStore = (param) => {
      if (route.value.query[param]) {
        searchStore[param] = route.value.query[param];
      }
    };

    const sendGTMEvents = (products) => {
      // Using window.location instead of route.value due to dynamic URL changes on the search page with history.replaceState
      const routerFullPath =
        window.location.pathname.toString() + window.location.search.toString();
      app.$gtm.push(getResultSearchDetails(routerFullPath));

      if (searchStore.products.length) {
        app.$gtm.push(getViewItemListDetails(products, 'search'));
      }
    };

    const initialFetchResult = ref(null);
    const initialFetch = async () => {
      searchStore.resetSearch();
      initialFetchResult.value = null;
      const modifiers = [
        listFilters({
          rangeFilterSettings: [
            {
              key: 'klevu_price',
              minMax: true
            }
          ],
          exclude: searchStore.searchFilterExcludes,
          limit: filterOptionsLimit,
          filterManager: manager
        }),
        applyFilters(getFitersFromUrl()),
        sendSearchEvent()
      ];

      searchStore.setSearchTerm(quickSearchStore.searchTerm || routeQuery);

      if (searchStore.searchTerm) {
        let productsQuery = {};
        let cmsQuery = {};
        let categoryQuery = {};

        if (isTypeEnabled(KlevuTypeOfRecord.Product)) {
          setParamToSearchStore('sort');
          setParamToSearchStore('itemsPerPage');
          setParamToSearchStore('page');

          const offset = (searchStore.page - 1) * searchStore.itemsPerPage;

          productsQuery = search(
            searchStore.searchTerm,
            {
              id: 'products',
              limit: searchStore.itemsPerPage,
              typeOfRecords: [KlevuTypeOfRecord.Product],
              sort: searchStore.sort,
              offset
            },
            ...modifiers
          );
        }

        if (isTypeEnabled(KlevuTypeOfRecord.Cms)) {
          cmsQuery = search(searchStore.searchTerm, {
            id: 'cms',
            limit: searchLimit,
            typeOfRecords: [KlevuTypeOfRecord.Cms]
          });
        }

        if (isTypeEnabled(KlevuTypeOfRecord.Category)) {
          categoryQuery = search(searchStore.searchTerm, {
            id: 'categories',
            limit: searchLimit,
            typeOfRecords: [KlevuTypeOfRecord.Category]
          });
        }

        initialFetchResult.value = await KlevuFetch(
          productsQuery,
          cmsQuery,
          categoryQuery
        );
        isFetchingEnded.value = true;
      } else {
        searchStore.setSearchTerm('');
        initialFetchResult.value = await KlevuFetch(
          trendingProducts(
            {
              id: 'search',
              limit: searchLimit,
              sort: searchStore.sort
            },
            ...modifiers
          )
        );
        isFetchingEnded.value = true;
      }

      prevRes = initialFetchResult.value;
      const searchResultProducts =
        initialFetchResult.value.queriesById('products');
      const searchResultCms = initialFetchResult.value.queriesById('cms');
      const searchResultCategories =
        initialFetchResult.value.queriesById('categories');

      if (
        !searchResultProducts &&
        !searchResultCms &&
        !searchResultCategories
      ) {
        return;
      }

      searchStore.showMore = Boolean(initialFetchResult.value.next);
      searchStore.setOptions(manager.options);

      if (isTypeEnabled(KlevuTypeOfRecord.Product)) {
        const skuList = [];
        for (const productRow of searchResultProducts.records) {
          skuList.push(productRow.sku);
        }
        searchStore.setProducts(skuList ?? []);
        products.value = await getProductsBySku(searchStore.products);
      }

      if (isTypeEnabled(KlevuTypeOfRecord.Cms)) {
        searchStore.setCmsPages(searchResultCms.records ?? []);
      }
      if (isTypeEnabled(KlevuTypeOfRecord.Category)) {
        searchStore.setCategories(searchResultCategories.records ?? []);
      }

      searchStore.totalResultsFound =
        searchResultProducts.meta.totalResultsFound +
        searchResultCms.meta.totalResultsFound;

      searchStore.totalProductsFound =
        searchResultProducts.meta.totalResultsFound;
      searchStore.totalCmsFound = searchResultCms.meta.totalResultsFound;

      sendGTMEvents(products.value);
    };

    const isTypeEnabled = (type) => typeOfRecords.includes(type);

    const updateParam = (param, value) => {
      let query = { ...route.value.query };

      if (param === 'itemsPerPage') {
        query.page = 1;
      }

      searchStore[param] = value;
      query[param] = value;

      router.push({
        query: { ...query }
      });
    };

    const productClick = (product) => {
      KlevuEvents.searchProductClick(product, searchStore.searchTerm);
    };

    // Doesn't support nested URLs
    // URL contains protocol, domain and path
    const getSlug = (url) => {
      const splittedUrl =
        url.split(
          '/'
        ); /* @TODO: Get store code and split through it to retrieve a full url path in 1 go, also necessary for category full path */
      // eslint-disable-next-line no-magic-numbers
      return `/${splittedUrl[splittedUrl.length - 3]}/${
        // eslint-disable-next-line no-magic-numbers
        splittedUrl[splittedUrl.length - 2]
      }/${splittedUrl[splittedUrl.length - 1]}`;
    };

    // Trigger initial fetch
    initialFetch();

    // Lifecycle hooks
    onMounted(async () => {
      vw = Math.max(
        document.documentElement.clientWidth || 0,
        window.innerWidth || 0
      );
      context.root.$on('do-search-page-search', initialFetch);
    });
    onBeforeUnmount(() => {
      context.root.$off('do-search-page-search', initialFetch);
    });

    const appendProducts = async () => {
      loading.value = true;
      let productsQuery = {};
      if (isTypeEnabled(KlevuTypeOfRecord.Product)) {
        setParamToSearchStore('itemsPerPage');
        setParamToSearchStore('page');

        const offset = searchStore.page * searchStore.itemsPerPage;
        searchStore.page++;

        productsQuery = search(searchStore.searchTerm, {
          id: 'products',
          limit: searchStore.itemsPerPage,
          typeOfRecords: [KlevuTypeOfRecord.Product],
          sort: searchStore.sort,
          offset
        });
      }

      const result = await KlevuFetch(productsQuery);
      const nextPageResults = result.queriesById('products');
      const skuList = [];

      if (isTypeEnabled(KlevuTypeOfRecord.Product)) {
        for (const productRow of nextPageResults.records) {
          skuList.push(productRow.sku);
        }

        const existingProducts = searchStore.products;
        const uniqueCharsNew = [...new Set(skuList)];
        if (uniqueCharsNew.length) {
          const result = await getProductsBySku(
            uniqueCharsNew.filter(
              (newSku) => !existingProducts.includes(newSku)
            ),
            // eslint-disable-next-line no-magic-numbers
            20,
            false
          );
          if (result?.length) {
            products.value.push(...result);
            sendGTMEvents(result);
          }
          searchStore.setProducts(uniqueCharsNew.concat(existingProducts));
        }
      }
      loading.value = false;
    };

    const getProductsBySku = async (
      sku_array,
      pageSize = 20,
      enableLoading = true
    ) => {
      try {
        const data = await getProductList(
          {
            filter: {
              sku: {
                in: sku_array
              }
            },
            pageSize: pageSize
          },
          enableLoading
        );

        const result = data?.items;
        //sort to have the same order as in search result from Klevu
        result.sort(
          (a, b) => sku_array.indexOf(a.sku) - sku_array.indexOf(b.sku)
        );
        return result;
      } catch (e) {
        Logger.error('getProductList [ERROR]', e);
      }
    };

    /*
     * This logic was derived from the VaimoHeaderSearchBar component, which uses the same method to retrieve category pages.
     * Only the developer who created it would know the reason behind this approach.*/
    // eslint-disable-next-line no-magic-numbers
    const getValidSlug = (url) => url.substring(url.indexOf(locale) + 5);
    const isPageHasCorrectUrl = (url) =>
      app.localePath(getValidSlug(url)).includes(locale);

    const validPages = computed(() => {
      return searchStore.categories.filter(
        (page) => page.name && isPageHasCorrectUrl(page.url)
      );
    });

    return {
      getValidSlug,
      validPages,
      searchStore,
      quickSearchStore,
      loading,
      manager,
      prevRes,
      vw,
      initialFetch,
      updateParam,
      productClick,
      KlevuSearchSorting,
      breadcrumbs,
      pageTitle,
      pageSubtitle,
      pageTitleNoResults,
      toggleFilterSidebar,
      getSlug,
      appendProducts,
      addItemToCart,
      products,
      productsLoading,
      initialFetchResult,
      isFetchingEnded,
      typeOfView,
      mainFiltersClick
    };
  }
};
</script>

<style lang="scss" scoped>
@import url('../assets/styles/KlevuSearch.scss');

#products,
#pages {
  display: none;

  &.active {
    display: block;
  }
}

.main_filters {
  display: flex;
  justify-content: flex-start;
  padding: 24px 60px;
  border-top: 1px solid #e6e5e5;
  border-bottom: 1px solid #e6e5e5;

  @include for-screen-s {
    padding: 12px 20px;
  }

  .main_filters_inside {
    display: flex;
    justify-content: center;
    align-items: center;
    width: calc(100% - 48px);

    @include for-screen-max-400 {
      width: calc(100% - 32px);
      justify-content: flex-end;
    }

    a {
      text-decoration: none;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: flex-start;
      padding: 6px 10px;
      margin: 0 10px;
      min-width: 120px;
      height: 28px;
      border-radius: 3px;
      border: 1px solid #e6e5e5;
      color: #808080;
      font-size: 12px;
      line-height: 16px;
      cursor: pointer;

      @include for-screen-s {
        margin: 0 5px;
        min-width: auto;
      }

      &.active {
        background: #000;
        border-color: #000;
        color: #fff;
      }

      span {
        position: relative;
        display: inline-block;
        margin-left: 5px;
        font-size: 10px;
        bottom: 2px;
      }
    }
  }
}

.breadcrumbs {
  padding-left: 20px;
  padding-top: 20px;

  .sf-breadcrumbs__breadcrumb {
    @apply font-light text-base text-gris;
    font-family: var(--font-family--primary);
  }

  ::v-deep {
    .sf-breadcrumbs__list-item {
      @apply flex items-center;

      &:not(:last-child) {
        &:after {
          content: '';
          border-top: 3px solid transparent;
          border-bottom: 3px solid transparent;
          border-left: 5px solid $gris;
          display: inline-block;
          margin: 0 10px;
        }
      }
    }
  }

  .current {
    @apply underline;
  }
}

#search {
  box-sizing: border-box;

  @include for-desktop {
    max-width: var(--container-max-width);
    margin: 0 auto;
  }

  .search-info {
    @apply flex items-center justify-center flex-col;

    .heading {
      text-align: center;
      margin-bottom: 40px;
    }

    .description {
      @apply mb-4md text-center text-lg font-light max-w-screen-sm;
    }

    @include for-screen-s {
      .description {
        @apply text-base mb-3md;
      }
    }
  }

  &::v-deep {
    .heading-skeleton {
      height: 77.34px;

      @include for-screen-s {
        height: 61.4px;
      }

      @include for-screen-max-480 {
        height: 96.4px;
      }
    }
  }
}

::v-deep .sf-sidebar__aside {
  @apply z-overlay;
}

@include for-screen-m-and-l {
  .search-info {
    @apply pt-lg;
  }
}

@include for-screen-l {
  .search-info {
    @apply pt-lg;
  }
}

@include for-screen-s {
  .search-info {
    @apply pl-md pr-md pt-2md;
  }
}

.search-layout {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  @include for-mobile {
    flex-direction: column;
  }

  .column {
    display: flex;
    flex-direction: column;
    flex: 1;
    width: 100%;

    @include for-mobile {
      flex: auto;
    }

    &.sidebar {
      max-width: 20%;
    }
  }
}

.main {
  &.section {
    padding: 0;
  }
}

.main {
  display: flex;
}

.search-title {
  margin-left: var(--spacer-sm);
  text-align: left;
}

.sidebar {
  flex: 0 0 15%;
  padding: 0 0 0 var(--spacer-sm);
  border: 1px solid var(--c-light);
  border-width: 0 1px 0 0;
}

.sidebar-filters {
  @apply z-base;
  --sidebar-title-display: none;
  --sidebar-top-padding: 0;

  @include for-desktop {
    --sidebar-content-padding: 0 var(--spacer-xl);
    --sidebar-bottom-padding: 0 var(--spacer-xl);
  }
}

.products {
  box-sizing: border-box;
  flex: 1;
  margin: 0;
  @apply pr-md pl-md;

  .vaimo-grid {
    padding: 0 !important;
  }

  @include for-screen-l {
    @apply pr-[60px] pl-[60px];
  }

  @include for-mobile {
    &__display-opt {
      display: flex;
      justify-content: space-around;
      align-items: center;
    }

    &__show-on-page {
      margin-top: 12px;
    }
  }

  .load-more {
    margin-top: var(--spacer-lg);
    margin-bottom: var(--spacer-xl);

    @include for-screen-s {
      margin-top: 0;
      margin-bottom: var(--spacer-lg);
    }
  }

  &__show-on-page {
    display: flex;
    justify-content: flex-end;
    align-items: baseline;

    &__label {
      font-family: var(--font-family--secondary);
      font-size: var(--font-size--sm);
    }
  }
}

.pleater {
  padding: 0 60px;

  @include for-screen-s {
    padding: 0 20px;
  }
}

.vaimo-information-pleats {
  border-top: 0.8px solid #e6e5e5;
  padding: 30px 0 20px 0;
  margin: 0 auto;
  box-sizing: border-box;

  &__item {
    padding: 0 0 10px;
    box-sizing: border-box;

    font-size: 16px;
    font-weight: 300;
    position: relative;
    cursor: pointer;
    color: $noir;
    font-family: var(--font-family--secondary);
    width: 100%;
    display: block;
    text-decoration: none;

    &:hover {
      &:after {
        transform: translate(4px, -50%);
        border-left-color: $gris_perle;
      }
    }

    &:after {
      content: '';
      width: 0;
      height: 0;
      border-top: 4px solid transparent;
      border-bottom: 4px solid transparent;
      border-left: 6px solid $primary;
      position: absolute;
      top: calc(50% - 5px);
      right: 5px;
      will-change: transform;
      transform: translate(0, -50%);
      transition: transform 0.2s ease;
    }

    @include for-screen-s {
      font-size: 14px;
    }
  }

  div:last-child {
    div {
      border: none;
    }
  }
}

.grid-layout {
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  justify-content: flex-start;
  padding-top: var(--spacer-3md);
  margin-left: -10px;
  margin-right: -10px;

  .vaimo-product-card {
    padding-left: 0;
    padding-right: 0;
    max-width: 100%;
  }

  .vaimo-product-card {
    padding-left: 10px;
    padding-right: 10px;
    width: 50%;
    margin-bottom: var(--spacer-lg);

    @include for-screen-s {
      margin-bottom: var(--spacer-3md);
    }

    @include for-screen-m-and-l {
      width: 25%;
    }
  }
}

.card {
  --product-card-title-font-weight: var(--font-weight--medium);
  --product-card-title-margin: var(--spacer-xs) 0 0 0;
  flex: 1 1 100%;

  @include for-desktop {
    --product-card-max-width: 100%;
    --product-card-title-font-weight: var(--font-weight--normal);
    --product-card-title-margin: var(--spacer-sm) 0 0 0;
    --product-card-add-button-bottom: var(--spacer-base);
  }
}

::v-deep .sf-product-card {
  --product-card-max-width: 100%;
  --product-card-padding: 0;

  @include for-screen-m-and-l {
    --product-card-max-width: 100%;
  }

  .card {
    will-change: transform, opacity;
  }

  &__add-button {
    @include for-mobile {
      opacity: 1;
      display: flex;
      bottom: 1rem;
      right: 0;
    }
  }
}

::v-deep {
  .sf-product-card__image-wrapper {
    width: 100%;
  }

  .sf-product-card::after {
    content: none;
  }
}

.category-filters {
  @apply z-sticky;
  position: sticky;
  top: 0;
  background: white;
  transition: top 0.4s;

  .button-wrapper {
    border-top: 0.8px solid $gris_nacre;

    .apply-filters-btn {
      padding: 20px;
    }

    ::v-deep {
      .vaimo-button__wrapper {
        justify-content: center;
      }
    }
  }

  .sort-wrapper {
    font-family: var(--font-family--primary);
    border-bottom: 0.5px solid $gris_nacre;
    padding: 20px;

    .title {
      font-size: 20px;
      color: $gris;
      font-weight: 300;
      cursor: pointer;
    }

    .reset-all {
      color: $gris;
      font-size: 12px;
      font-weight: 400;
      cursor: pointer;
      text-decoration: underline;
    }
  }

  .vaimo-sort {
    padding: 20px;
  }

  .filters-wrapper {
    &__title-wrapper {
      font-family: var(--font-family--primary);
      padding: 20px;
      border-bottom: 0.5px solid $gris_nacre;
      border-top: 0.5px solid $gris_nacre;
      margin-bottom: 20px;

      .title {
        font-size: 20px;
        color: $gris;
        font-weight: 300;
        cursor: pointer;
      }

      .reset-all {
        color: $gris;
        font-size: 12px;
        font-weight: 400;
        cursor: pointer;
        text-decoration: underline;
      }
    }

    .category-filter {
      margin-bottom: 30px;
    }
  }

  .vaimo-selected-filters {
    height: 30px;
    overflow: hidden;
  }

  .vaimo-selected-filters-show-more {
    overflow: auto;
  }

  &:before {
    @apply z-base;
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: -12px;
    height: 12px;
    width: 100%;
    background: $blanc;
  }
}

.scroll-up {
  .category-filters {
    top: 79px;

    @include for-screen-m-and-l {
      top: var(--sticky-header-height);
    }
  }
}

@import '@/diptyqueTheme/assets/styles/atoms/LoadMore';
</style>

<style lang="scss">
.search-info {
  .vaimo-heading__subtitle-default {
    font-family: var(--font-family--secondary);
    font-size: 22px;
    font-style: italic;
    font-weight: 400;
    line-height: 30px;
    text-align: center;
    text-transform: lowercase;
  }

  .text {
    margin-bottom: 67px;

    p {
      font-family: var(--font-family--secondary);
      font-style: normal;
      font-weight: 400;
      font-size: 22px;
      line-height: 135%;
      text-align: center;
      color: #000000;
    }
  }
}

.filters__buttons {
  .sf-button--full-width.sf-button {
    width: 100%;
  }

  .sf-button--full-width {
    background: #000000;
  }
}
</style>
